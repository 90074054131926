import * as React from "react";
import { GraphService } from "../../helpers/graph.service";
import { IPersonaProps } from "@fluentui/react";
import axios from "axios";

export const Troubleshoot: React.FunctionComponent<{ graphService: GraphService }> = ({ graphService } ) => {
  const [error, setError] = React.useState<string>();
  const [results, setResults] = React.useState<IPersonaProps[]>([]);

  const [status, setStatus] = React.useState<any>();
  const [statusText, setStatusText] = React.useState<any>();
  const [token, setToken] = React.useState<any>();
  const [tokenException, setTokenException] = React.useState<string>();

  React.useEffect(() => {
    const callToken = async () => {
      try {
        const clientToken = await OfficeRuntime.auth.getAccessToken();
        let tokenResponse = await axios({
            url: "https://backend.dev.prop.addin.m365.skyguide.ch/api/token",
            method: 'post',
            headers: { 'Authorization': `Bearer ${clientToken}` }    
        });
        setStatus(tokenResponse.status)
        setStatusText(tokenResponse.statusText)
        if (tokenResponse.status === 200) {
          setToken(tokenResponse.data);
        }
      }
      catch (ex) {
        setTokenException(ex);
      }
      
      try {
        const props = await graphService.getUserBySearchCriteria("Marc", 10);
        setResults(props);
      }
      catch (ex) {
        setError(JSON.stringify(ex));
      }
    }
    callToken();
  }, [])
    
  return <div>
    <h1>Graph</h1>
    Results
    <pre>
      {JSON.stringify(results, null, 2)}
    </pre>
    Errors:
    <pre>
      {error}
    </pre>
    <h1>Token</h1>
    Results
    <pre>
      {status}
    </pre>
    <pre>
      {statusText}
    </pre>
    <pre>
      {token}
    </pre>
    Errors:
    <pre>
      {tokenException}
    </pre>
  </div> 
};
