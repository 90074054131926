import * as React from "react";

export const OfficeToken: React.FunctionComponent = () => {
  const [token, setToken] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    const callToken = async () => {
      try {
        setToken(await OfficeRuntime.auth.getAccessToken());
      }
      catch (ex) {
        setToken(JSON.stringify(ex))
      }
    }
    callToken();
  }, [])

  if (!token)
    return <React.Fragment>Retrieving token</React.Fragment>
    
  return <React.Fragment>Token: "{token}"</React.Fragment>;
};
